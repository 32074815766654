<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleId" >
        <a-input v-model="form.vehicleId" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="总里程 单位：米" prop="odo" >
        <a-input v-model="form.odo" placeholder="请输入总里程 单位：米" />
      </a-form-model-item>
      <a-form-model-item label="总时长 单位：秒" prop="ctlTime" >
        <a-input v-model="form.ctlTime" placeholder="请输入总时长 单位：秒" />
      </a-form-model-item>
      <a-form-model-item label="智驾里程 单位：米" prop="autoCtlOdom" >
        <a-input v-model="form.autoCtlOdom" placeholder="请输入智驾里程 单位：米" />
      </a-form-model-item>
      <a-form-model-item label="遥控里程 单位：米" prop="remoteCtlOdom" >
        <a-input v-model="form.remoteCtlOdom" placeholder="请输入遥控里程 单位：米" />
      </a-form-model-item>
      <a-form-model-item label="智驾时长 单位：秒" prop="autoCtlTime" >
        <a-input v-model="form.autoCtlTime" placeholder="请输入智驾时长 单位：秒" />
      </a-form-model-item>
      <a-form-model-item label="遥控时长 单位：秒" prop="remoteCtlTime" >
        <a-input v-model="form.remoteCtlTime" placeholder="请输入遥控时长 单位：秒" />
      </a-form-model-item>
      <a-form-model-item label="统计日期" prop="summaryTime" >
        <a-date-picker style="width: 100%" v-model="form.summaryTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCtrlmodeData, addCtrlmodeData, updateCtrlmodeData } from '@/api/iot/ctrlmodeData'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        odo: null,
        ctlTime: null,
        autoCtlOdom: null,
        remoteCtlOdom: null,
        autoCtlTime: null,
        remoteCtlTime: null,
        summaryTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleId: [
          { required: true, message: '车辆编号不能为空', trigger: 'blur' }
        ],
        summaryTime: [
          { required: true, message: '统计日期不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        odo: null,
        ctlTime: null,
        autoCtlOdom: null,
        remoteCtlOdom: null,
        autoCtlTime: null,
        remoteCtlTime: null,
        summaryTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCtrlmodeData(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCtrlmodeData(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addCtrlmodeData(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
