var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆编号" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总里程 单位：米", prop: "odo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总里程 单位：米" },
                model: {
                  value: _vm.form.odo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "odo", $$v)
                  },
                  expression: "form.odo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总时长 单位：秒", prop: "ctlTime" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总时长 单位：秒" },
                model: {
                  value: _vm.form.ctlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ctlTime", $$v)
                  },
                  expression: "form.ctlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "智驾里程 单位：米", prop: "autoCtlOdom" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入智驾里程 单位：米" },
                model: {
                  value: _vm.form.autoCtlOdom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoCtlOdom", $$v)
                  },
                  expression: "form.autoCtlOdom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "遥控里程 单位：米", prop: "remoteCtlOdom" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入遥控里程 单位：米" },
                model: {
                  value: _vm.form.remoteCtlOdom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remoteCtlOdom", $$v)
                  },
                  expression: "form.remoteCtlOdom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "智驾时长 单位：秒", prop: "autoCtlTime" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入智驾时长 单位：秒" },
                model: {
                  value: _vm.form.autoCtlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoCtlTime", $$v)
                  },
                  expression: "form.autoCtlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "遥控时长 单位：秒", prop: "remoteCtlTime" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入遥控时长 单位：秒" },
                model: {
                  value: _vm.form.remoteCtlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remoteCtlTime", $$v)
                  },
                  expression: "form.remoteCtlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "统计日期", prop: "summaryTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.summaryTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "summaryTime", $$v)
                  },
                  expression: "form.summaryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }