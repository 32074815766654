import request from '@/utils/request'

// 查询车辆控制模式数据列表
export function listCtrlmodeData (query) {
  return request({
    url: '/iot/ctrlmodeData/list',
    method: 'get',
    params: query
  })
}

// 查询车辆控制模式数据详细
export function getCtrlmodeData (id) {
  return request({
    url: '/iot/ctrlmodeData/' + id,
    method: 'get'
  })
}

// 新增车辆控制模式数据
export function addCtrlmodeData (data) {
  return request({
    url: '/iot/ctrlmodeData',
    method: 'post',
    data: data
  })
}

// 修改车辆控制模式数据
export function updateCtrlmodeData (data) {
  return request({
    url: '/iot/ctrlmodeData',
    method: 'put',
    data: data
  })
}

// 删除车辆控制模式数据
export function delCtrlmodeData (id) {
  return request({
    url: '/iot/ctrlmodeData/' + id,
    method: 'delete'
  })
}

// 导出车辆控制模式数据
export function exportCtrlmodeData (query) {
  return request({
    url: '/iot/ctrlmodeData/export',
    method: 'get',
    params: query
  })
}
